import React from 'react';
import { rhythm, setFontSize } from '../../styles/util';
import styled from '@emotion/styled';
import SummaryTable from './SummaryTable';

const TableWrap = styled.div`width: 100%; overflow: scroll;`;

function Summary({product, addons, addonOptions, site, day, donation, paymentOpt, coupon, headingText}) {
  return (
    <>
      <h3 css={setFontSize(120)}>{headingText}</h3>

      <TableWrap>
        <SummaryTable
          product={product}
          addons={addons}
          addonOptions={addonOptions}
          site={site}
          day={day}
          donation={donation}
          paymentOpt={paymentOpt}
          coupon={coupon}
        />
      </TableWrap>      
    </>
  );
}

export default Summary;