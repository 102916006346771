import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const Checkout = props => {
  return (
    <Elements 
      stripe={stripePromise}
      options={{fonts: [{
        cssSrc: 'https://fonts.googleapis.com/css?family=Bitter&display=swap'
      }]}}
    >
      <CheckoutForm {...props} />
    </Elements>
  );
};

export default Checkout;