import React, { useState } from 'react'
import styled from '@emotion/styled';
import {priceFormatter as formatter} from 'src/util';
import Card from './Card';

const Stretch = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export default ({id, name, description, fee, onlinePriceOverride, isSelected, onSelect}) => (
  <Card name={name} isSelected={isSelected} maxWidth="320px">
    <Stretch>
      <p css={{textAlign: 'left'}}>{description}</p>
      <p>
        <input type="radio" value={id} checked={isSelected} name="product" onChange={onSelect} />
        {' '}
        {(fee >= 0 ? '+' : '') + formatter.format(fee)}
      </p>

    </Stretch>

  </Card>
);
