import React from 'react';
import CardWrap from '../CardWrap';
import Product from '../Product';
import ProductAddon from '../ProductAddon';
import { StepTitle, StepDescription } from '../Steps';

const ProductStep = ({
  allProducts, productId, setProductId, allAddons, addonIds, setAddonIds, 
  title, description, addonsText
}) => {
  function updateAddonOption(addonId, optName, optVal) {
    const options = addonIds[addonId] || {}
    setAddonIds({
      ...addonIds,
      [addonId]: { ...options, [optName]: optVal }
    })
  }

  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>

      <CardWrap>
        {allProducts.map(p =>
          <Product
            key={p.id}
            {...p}
            isSelected={productId === p.id}
            onSelect={() => setProductId(p.id)} />)}
      </CardWrap>

      <StepTitle>{addonsText}</StepTitle>

      <CardWrap>
        {allAddons.map(a =>
          <ProductAddon
            key={a.id}
            {...a}
            isSelected={!!addonIds[a.id]}
            onSelect={(e) => setAddonIds({...addonIds, [a.id]: e.target.checked && {}})}
            optionSelections={addonIds[a.id]}
            onUpdateOption={(optName, optVal) => updateAddonOption(a.id, optName, optVal)} />)}
      </CardWrap>
    </>
  )
};

export default ProductStep;