import React, { useState } from 'react'
import { css } from '@emotion/core';
import { rhythm, setFontSize } from '../../styles/util';
import { colors } from '../../styles/constants';
import styled from '@emotion/styled';
import {priceFormatter as formatter} from 'src/util';
import Card from './Card';

import {dayNames} from 'src/util';

const SiteWrap = styled.div`padding: ${rhythm(1/2)} 0;`;
const Secondary = styled.span`${setFontSize(80, .75)}; opacity: .8;`;
const Title = styled.div`${setFontSize(100, .75)};`;
const Flag = styled.span`${setFontSize(70, .75)}; color: ${colors.hi1}; font-style: italic;`;
const Details = styled.div`${setFontSize(100, .75)};`;
const siteStyle = css`
  display: flex;
  align-items: center;
  text-align: left;
  margin: ${rhythm(1/4)} 0;

  > input { margin-right: ${rhythm(1/2)}; flex-shrink: 0; }
`
const Site = ({id, name, address, fee = 0, feeDescription, flag, isSelected, onSelect}) => (
  <label css={siteStyle}>
    <input type="radio" value={id} checked={isSelected} name="product-site" onChange={onSelect} />
    <Details>
      <Title>{name} {flag && <Flag>{flag}</Flag>}</Title>
      {!!address && <Secondary>{address}</Secondary>}
      {fee > 0 && 
        <div> 
          {'+' + formatter.format(fee) + ' '}
          {!!feeDescription && <Secondary>{feeDescription}</Secondary>}
        </div>}
    </Details>
  </label>
)

export default ({day, sites, isSelected, selectedSite, onSelect}) => {
  
  return (
    <Card name={dayNames[day]} isSelected={isSelected} tag="div" maxWidth="400px">

      <SiteWrap>
        {sites.map(site => 
          <Site 
            key={site.id}
            {...site}
            isSelected={site.id == selectedSite} 
            onSelect={() => onSelect(site.id)}
          />)}
      </SiteWrap>
      

    </Card>
  )
}