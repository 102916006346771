// Summary Table only - used both in emails and in site
import React from 'react';
import util from '../../util';
import { rhythm, setFontSize } from '../../styles/util';

const pf = util.priceFormatter;

// NB: inline styles are used here for better HTML email compatibility
const Td = ({right, pad, padTop, nowrap, colSpan, children}) => (
  <td colSpan={colSpan} style={{
    textAlign: right ? 'right' : 'left',
    paddingRight: pad ? rhythm(1/2) : 0,
    paddingTop: padTop ? rhythm(1/4) : 0,
    whiteSpace: nowrap ? 'nowrap' : null,
    verticalAlign: 'top'
  }}>{children}</td>
);

const SummaryTable = ({
  product, addons, addonOptions, site, day, donation, paymentOpt,
  coupon, comments, isPaid = false, width='100%'
}) => {
  const [total, discount] = util.getTotal(product, addons, site, donation, paymentOpt, coupon);
  const due = util.getDue(product, addons, site, donation, paymentOpt, coupon);
  const showCouponNewCustDisclaimer = !isPaid && coupon?.newCustomersOnly;

  return (
    <table style={{
      borderCollapse: 'collapse',
      width,
      minWidth: '350px'
    }}>
      <tbody>
        <tr>
          <Td pad>Product:</Td>
          <Td pad>{product.name}</Td>
          <Td right>{pf.format(product.price)}</Td>
        </tr>

        {addons.map((addon, i) =>
          <tr key={addon.id}>
            <Td pad>{i == 0 && 'Addon(s):'}</Td>
            <Td pad>
              {addon.name}
              {Object.keys(addonOptions[addon.id]).length > 0 &&
                ` (${Object.values(addonOptions[addon.id]).join(', ')})`}
            </Td>
            <Td right>{pf.format(addon.price)}</Td>
          </tr>)}

        <tr>
          <Td pad nowrap>Pickup Site:</Td>
          <Td pad>{site.name}</Td>
          <Td right>{!!site.fee && pf.format(site.fee)}</Td>
        </tr>

        <tr>
          <Td pad nowrap>Pickup Day:</Td>
          <Td pad>{util.dayNames[day]}</Td>
          <Td right></Td>
        </tr>

        {!!donation && 
          <tr>
            <Td pad nowrap>HFfAF Donation:</Td>
            <Td pad></Td>
            <Td right>{pf.format(donation)}</Td>
          </tr>}

        <tr>
          <Td pad nowrap>Payment Option:</Td>
          <Td pad>{paymentOpt.name}</Td>
          <Td right>{!!paymentOpt.fee && pf.format(paymentOpt.fee)}</Td>
        </tr>

        {coupon && 
          <tr>
            <Td pad>Coupon Code:</Td>
            <Td pad>{coupon.code}{showCouponNewCustDisclaimer && '*'}</Td>
            <Td right>{pf.format(-discount)}</Td>
          </tr>}

        {showCouponNewCustDisclaimer &&
          <tr style={{...setFontSize(85), color: '#555555'}}>
            <Td colSpan="3">*Valid for new customers only</Td>
          </tr>}

        {!!comments &&
          <tr>
            <Td pad>Comments:</Td>
            <Td pad>{comments}</Td>
            <Td right />
          </tr>}

        {/* spacer */}
        <tr><Td padTop colSpan="3" /></tr>
        
      </tbody>
      <tfoot style={{borderTop: '1px solid black'}}>
        <tr>
          <Td pad padTop>Total:</Td>
          <Td pad padTop></Td>
          <Td right padTop>{pf.format(total)}</Td>
        </tr>

        <tr>
          <Td pad><strong>{isPaid ? 'Paid' : 'Due'} Today:</strong></Td>
          <Td pad></Td>
          <Td right><strong>{pf.format(due)}</strong></Td>
        </tr>
      </tfoot>
    </table>
  );
}

export default SummaryTable;