import React from 'react';
import styled from '@emotion/styled'
import { rhythm } from '../styles/util';

function getColor(p) {
  switch (p.type) {
    case 'error': return 'rgb(182,33,33)';
    case 'success': return 'rgb(1,156,1)';
  }
}

function getBg(p) {
  switch (p.type) {
    case 'error': return 'rgb(253, 225, 227)';
    case 'success': return 'rgb(220, 255, 212)';
  }
}

const Message = styled.p`
  border: 2px solid;
  border-radius: 8px;
  color: ${getColor};
  background: ${getBg};
  padding: ${rhythm(1/2)};
`

export default Message;