import React from 'react';
import {dayNames} from 'src/util';
import CardWrap from '../CardWrap';
import ProductSites from '../ProductSites';
import { StepTitle, StepDescription } from '../Steps';

const PickupStep = ({allSites, siteId, setSiteId, day, setDay, title, description}) => (
  <>
    <StepTitle>{title}</StepTitle>
    <StepDescription>{description}</StepDescription>

    <CardWrap>
      {Object.entries(sitesByDay(allSites)).map(([curDay, sites]) =>
        sites.length ?
          <ProductSites 
            key={curDay} 
            day={curDay} 
            sites={sites}
            isSelected={day == curDay}
            selectedSite={siteId}
            onSelect={id => {
              setDay(curDay);
              setSiteId(id);
            }}
          />
        : null)}
    </CardWrap>
  </>
);

function sitesByDay(sites) {
  const byDay = dayNames.map(() => []);

  for (let node of sites) {
    for (let day of node.days) {
      byDay[day].push(node);
    }
  }

  return byDay;
}

export default PickupStep;