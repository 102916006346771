import axios from 'axios';
import React, {useState} from 'react';
import Button from '../Button';
import Input from '../Input';
import Message from '../Message';
import {oneLineFormStyle} from 'src/styles/util'
import { css } from '@emotion/core';

const style = css`
  ${oneLineFormStyle}
  > input { max-width: none; }
`

const ApplyCoupon = ({coupon, setCoupon}) => {
  const [code, setCode] = useState('');
  const [incorrectCode, setIncorrectCode] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()
    setIncorrectCode('')
    // check code
    try {
      setIsLoading(true)
      const { data: coupon } = await axios.get('/.netlify/functions/get-coupon', {params: {code}})
      setCoupon(coupon)
    } catch(e) {
      setIncorrectCode(code)
    } finally {
      setIsLoading(false)
    }
  }

  function onInput (e) {
    setIncorrectCode('')
    setCode(e.target.value)
  }

  const alreadyApplied = coupon?.code?.toLowerCase() === code.toLowerCase()
  const buttonText = isLoading ? 'Applying...' : (alreadyApplied ? 'Applied' : 'Apply')

  return (
    <>
      <form css={style} onSubmit={handleSubmit}>
        <Input
          value={code}
          onChange={onInput}
          placeholder="Coupon code"
          name="code"
          border
        />
        <Button secondary title="Apply coupon" disabled={alreadyApplied || isLoading}>{buttonText}</Button>
      </form>
      {incorrectCode && <Message type="error">The code "{incorrectCode}" was not found</Message>}
    </>
  )
}

export default ApplyCoupon
