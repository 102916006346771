import React from 'react';
import Container from '../../Container';
import Summary from '../Summary';
import Checkout from '../Checkout';
import Message from '../../Message';
import { StepTitle, StepDescription } from '../Steps';
import ChargeDescription from '../ChargeDescription';
import ApplyCoupon from '../ApplyCoupon';
import { rhythm } from '../../../styles/util';
import {getDue} from '../../../util';

const CheckoutStep = ({
  product, addons, site, donation, paymentOpt,
  productId, addonIds, siteId, day, paymentOptId,
  coupon, setCoupon,
  checkoutFields, setCheckoutFields, paymentStatus, setPaymentStatus, 
  title, description, summaryText, agreementFile,
  stepNum, gotoStep
}) => {
  const success = paymentStatus === 'success';
  const due = getDue(product, addons, site, donation, paymentOpt, coupon);
  const setStatus = status => {
    // re-navigate to the current step to reset scroll position
    if (status === 'success') gotoStep(stepNum);
    setPaymentStatus(status);
  }

  return <>
    

    <Container width="600px" minGap={rhythm(1)}>
      <StepTitle>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>

      {success && <Message type="success">Thank you, your order is complete. You will receive a confirmation email shortly.</Message>}
      
      <Summary 
        product={product} 
        addons={addons} 
        addonOptions={addonIds}
        site={site} 
        day={day} 
        donation={donation} 
        paymentOpt={paymentOpt}
        coupon={coupon}
        headingText={summaryText}
      />

      {!success && <ApplyCoupon {...{coupon, setCoupon}} />}

      {!success && <ChargeDescription {...{paymentOpt, due}} />}

      <Checkout 
        orderDetails={{
          productId,
          addonIds: Object.entries(addonIds).filter(e => e[1]).map(e => e[0]),
          addonOptions: addonIds,
          siteId,
          day,
          donation,
          paymentOptId,
          couponCode: coupon?.code
        }} 
        fields={checkoutFields} 
        setFields={setCheckoutFields}
        status={paymentStatus} 
        setStatus={setStatus}
        clearCoupon={() => setCoupon(null)}
        agreementUrl={agreementFile?.publicURL}
      />
    </Container>
  </>
};


export default CheckoutStep;