import React from 'react';
import CardWrap from '../CardWrap';
import PaymentOption from '../PaymentOption';
import { StepTitle, StepDescription } from '../Steps';


const PaymentOptStep = ({allPaymentOpts, paymentOptId, setPaymentOptId, title, description}) => (
  <>
    <StepTitle>{title}</StepTitle>
    <StepDescription>{description}</StepDescription>

    <CardWrap>
      {allPaymentOpts.map(o => {
        if (o.invisible) return null;
        
        return <PaymentOption 
        key={o.id}
        {...o}
        isSelected={paymentOptId == o.id} 
        onSelect={() => setPaymentOptId(o.id)} 
      />
      })}
    </CardWrap>
  </>
);


export default PaymentOptStep;