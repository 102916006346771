import React from 'react';
import Center from '../../Center';
import Input from '../../Input';
import { StepTitle, StepDescription } from '../Steps';

const DonationStep = ({donation, setDonation, title, description}) => (
  <>
    <StepTitle>{title}</StepTitle>
    <StepDescription>{description}</StepDescription>

    <Center>
      $ <Input border type="number" value={donation} onChange={e => setDonation(e.target.value)} />
    </Center>
  </>
);


export default DonationStep;