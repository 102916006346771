import React, { useState } from 'react'
import { Tooltip } from 'react-tippy';
import { FaQuestionCircle } from 'react-icons/fa';

// import { css } from '@emotion/core';
import { rhythm, setFontSize } from '../../styles/util';
import { colors } from '../../styles/constants';
import styled from '@emotion/styled';
import { css } from '@emotion/core';


const Card = styled.label`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  text-align: center;
  box-shadow: 
    ${p => p.isSelected && `0 0 0 4px ${colors.hi2},`}
    0 0 6px ${p => p.isSelected ? '4px' : 0} black;

  min-width: ${p => p.minWidth || 0};
  max-width: ${p => p.maxWidth || 'none'};

  > h3 {
    margin: 0;
    ${setFontSize(100)};
    padding: ${rhythm(1)};
    background: ${colors.hi1};
    color: ${colors.light}
  }

  > section {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 ${rhythm(1)};
  }
`

const TipClickTarget = styled.div`
    position: absolute;
    top: -11px; bottom: -10px; left: -10px; right: -10px;
    /* background: rgba(0,255,0,.5); */
    border-radius: 50%;
`

export default ({name, description, children, tag='label', ...props}) => {
  const CardComp = Card.withComponent(tag);
  return (
    <CardComp {...props}>
      <h3>
        {name} {' '}
        {description && 
          <Tooltip title={description} css={{position: 'relative', paddingLeft: '2px'}} arrow interactive >
            <FaQuestionCircle css={{position: 'relative', top: '2px'}} />
            <TipClickTarget onClick={e => e.preventDefault()} />
          </Tooltip>}
      </h3>
      <section>
        {children}
      </section>
    </CardComp>
  )
};
