import React, {useState, useRef} from 'react';
import Button from '../Button';
import styled from '@emotion/styled';
import { rhythm, setFontSize, padded, center } from '../../styles/util';
import { colors, MQ } from '../../styles/constants';
import { css } from '@emotion/core';
import Container from '../Container';
import {priceFormatter as formatter} from 'src/util';

const StepNav = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rhythm(1)} 0;
  
  > *:not(:last-child) {margin-right: ${rhythm(1)}}
`
const StepNavItem = styled.button`
  width: ${rhythm(1)};
  border: 2px solid ${colors.hi1};
  background: ${p => p.complete ? colors.hi1 : colors.light};
  color: ${p => p.complete ? colors.light : colors.hi1};
  font: inherit;
  opacity: ${p => p.navigable ? 1 : .5};
  cursor: ${p => p.navigable ? 'pointer' : 'not-allowed'};
  position: relative;
  box-sizing: border-box;

  :not(:last-of-type)::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    width: calc(${rhythm(1)} + 2px);
    border-top: 2px solid ${colors.hi1};
  }
`

const ButtonRow = styled.div`
  margin: ${rhythm(1)} 0;
  position: relative;
  text-align: center;
  min-height: ${rhythm(2)};
  
  ${MQ.SM} {
    min-height: ${rhythm(4)};
  }
`
const buttonStyle = css`position: absolute; bottom: ${rhythm(1/2)};`;
const leftButtonStyle = css`${buttonStyle} left: ${rhythm(1)};`;
const rightButtonStyle = css`${buttonStyle} right: ${rhythm(1)};`;

function Steps({children, canVisitStep, totalPrice, dueToday = 0}) {
  const [step, setStep] = useState(0);
  const max = React.Children.count(children);
  const stepsEl = useRef(null);

  function canGoto(step) {
    return step >= 0 && step < max && canVisitStep(step);
  }

  function goto(step) {
    if (canGoto(step)) {
      setStep(step);

      // reset scroll
      let offset = stepsEl.current.getBoundingClientRect();
      if (offset.top < 0) window.scrollTo(0, window.scrollY + offset.top);
    }
  }

  return (
    <div ref={stepsEl}>
      <StepNav>
        {[...Array(max)].map((_, i) => 
          <StepNavItem
            key={i}
            complete={step >= i} 
            navigable={step === i || canGoto(i)}
            onClick={() => goto(i)}
          >{i+1}</StepNavItem>)}
      </StepNav>

      {React.cloneElement(children[step], {stepNum: step, gotoStep: goto})}
      
      <Container width="600px">
        <ButtonRow>
          {step > 0 && 
            <Button 
              primary 
              css={leftButtonStyle} 
              onClick={() => goto(step - 1)} 
              disabled={!canGoto(step - 1)}
            >{'«'} Back</Button>}

          {step < max-1 && 
            <>
              Total: {formatter.format(totalPrice)}<br />
              Due Today: {formatter.format(dueToday)}
            </>}

          {step < max-1 && 
            <Button 
              secondary
              css={rightButtonStyle}
              onClick={() => goto(step + 1)}
              disabled={!canGoto(step + 1)}
            >Next {'»'}</Button>}
        </ButtonRow>
      </Container>
    </div>
  );
}

const StepTitle = styled.h2`
  ${setFontSize(120)};
  text-align: center;
  ${padded};
`

const StepDescription = ({children}) => {
  return React.Children.count(children) > 0 ? (
    <Container><p css={[center, padded]}>{children}</p></Container>
  ) : null;
}

export {
  Steps,
  StepTitle,
  StepDescription
};