import React, { useState } from 'react'
import Img from 'gatsby-image'
import { rhythm, setFontSize } from '../../styles/util';
import styled from '@emotion/styled';
import {css} from '@emotion/core'
import {priceFormatter as formatter} from 'src/util';
import Card from './Card';

const ImageWrap = styled.div`margin: 0 ${rhythm(-1)};`;

const optionsStyle = css`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;

  li:not(:first-child) { margin-left: ${rhythm(.5)}; }
  select { padding: 1px; }
`

const Product = ({
  id, name, description, price, image, 
  options, optionSelections = {}, onUpdateOption,
  isSelected, onSelect, inputType = 'radio'
}) => (
  <Card name={name} description={description} isSelected={isSelected} minWidth="280px">
    {image && 
      <ImageWrap key={id}>
        <Img fluid={image.childImageSharp.fluid} alt={`${name} example`} />
      </ImageWrap>}

    <p>
      <input type={inputType} value={id} checked={isSelected} name="product" onChange={onSelect} />
      {' '}
      {formatter.format(price)}
    </p>

    {options && isSelected &&
      <ul css={optionsStyle}>
        {options.map(option =>
          <li key={option.name}>
            {option.name}:{' '}
            <select 
              value={optionSelections[option.name] || ''}
              onChange={e => onUpdateOption(option.name, e.target.value)}
            >
              <option value=''>Select</option>
              {option.choices.map(choice =>
                <option key={choice}>{choice}</option>)}
            </select>
          </li>)}
      </ul>}
  </Card>
);

export default React.memo(Product);