import styled from "@emotion/styled";
import { rhythm } from "../../styles/util";
import Card from './Card';

const CardWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    margin: 0 ${rhythm(1/2)} ${rhythm(1)};
  }
`

export default CardWrap;