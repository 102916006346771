import React from 'react';
import {replaceVars, priceFormatter} from '../../util';

const ChargeDescription = ({paymentOpt, due}) => (
  <p>
    {replaceVars(paymentOpt.chargeDescription, {
      '{amount_due}': priceFormatter.format(due)
    })}
  </p>
);

export default ChargeDescription;